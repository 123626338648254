<template>
  <v-container>
    <h2 class="mb-5">Add User Profile</h2>
          <UserProfileForm @createUserProfile="createUserProfile" @back="$router.push({ name: 'MobileUserProfileIndex' })"></UserProfileForm>
  </v-container>
</template>
<script>
 import MobileRestResource from "@/services/dataServiceMobile.js";
  const mobileService = new MobileRestResource();
export default {
  components: {
    UserProfileForm: () => import("./../../components/userProfileForm.vue"),
  },
  methods: {
    createUserProfile(userProfile) {
      this.$setLoader()
      mobileService.createUserProfile(userProfile).then(() => {
        this.$disableLoader()
        this.$router.push({ name: "MobileUserProfileIndex" });
      });
    },
  },
};
</script>
